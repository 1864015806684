body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

:root {
  --primary-color: #fbc91d;
  --secondary-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  background-color: #fff;
  padding: 10px 10px;
}

nav .inner-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  width: 1200px;
  margin: auto;
}

.logo-wrapper{

}

.logo-wrapper img{
  height: 50px;
}

.links-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.links-wrapper .link{
  margin-left: 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.content-wrapper{
  max-width: calc(100% - 80px);
  width: 1200px;
  margin: auto;
  background-color: white;
  padding: 25px;
  margin-top: 15px;
}

.primaryButton{
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
  font-size: 14px;
  font-weight: bold;
  text-transform: 1px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
}

.primaryButton:hover{
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
}

.error{
  color: red;
}